



import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import "./Learnmore.css";

const images = [
  "https://content.jdmagicbox.com/v2/comp/rangareddy/a6/040pxx40.xx40.230613122359.m5a6/catalogue/nihoc-international-school-rangareddy-schools-FjAn7GMUTa-250.jpg",
  "https://static.wixstatic.com/media/e36841_baea19b3e29e4b678af95ef8e2627a36~mv2.jpg/v1/crop/x_0,y_42,w_3000,h_1819/fill/w_636,h_332,al_c,q_80,usm_0.66_1.00_0.01,enc_avif,quality_auto/View_5%20copy.jpg",
  "https://content.jdmagicbox.com/v2/comp/rangareddy/a6/040pxx40.xx40.230613122359.m5a6/catalogue/nihoc-international-school-rangareddy-schools-XNwl8Jodz0-250.jpg",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5KZzsUvnSB9eGe0ISx8hn7Ucy8SBqTm6g2A&s",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqe6bo18cTQbLOKyzFrpqe2bkV2ZhHqYaKCQ&s",
  "https://content3.jdmagicbox.com/v2/comp/rangareddy/h9/040pxx40.xx40.230613122400.k1h9/catalogue/nihoc-the-international-school-rangareddy-schools-20icdenpz5.jpg",
  "https://images.jdmagicbox.com/v2/comp/rangareddy/m4/040pxx40.xx40.230919114600.z2m4/catalogue/pallavi-international-school-singapore-township-rangareddy-schools-bna8rd2t87-250.jpg",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmxGm9ebrFERJ6DwTNbAGSH2aOSryG-rif_8uQQ_KoPX-Lv3dBTqVDdywURbCe6N4NY1o&usqp=CAU",
];

const LearnMoreNihocSports = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <>
      <Navbar />

      <div className="LearnMorePage-container">
        <h1 className="LearnMorePage-title">
        Learnorama Sports summer camp 2025
        </h1>
        <p className="LearnMorePage-subtitle">
          Last year’s summer camp was a huge hit!
        </p>

        <div className="LearnMorePage-section">
          <h2 className="LearnMorePage-section-title">Camp Overview</h2>
          <p className="LearnMorePage-section-content">
            Over 336 students joined us and had an unforgettable experience.
            They explored exciting activities and launched their own websites
            and products using Artificial Intelligence.
          </p>

          <p className="LearnMorePage-section-content">
            Students even gave interviews during a grand press meet to showcase
            their creations. We ended the camp with a spectacular closing
            ceremony to celebrate their journey!
          </p>

          <p className="LearnMorePage-section-content">
            And now, we’re back—bigger and better!
          </p>

          <p className="LearnMorePage-section-content">
            This year, we are hosting the camp at:
          </p>

          <div className="flyers-container">
            <h1 id="font-color" className="LearnMorePage-title">
              Nihoc International School, Peerzadiguda
            </h1>
            <div className="flyers-wrapper">
              <div className="flyer-card">
                <img
                  src="./images/LearnMoreNihocSports01.jpg"
                  alt="Flyer 1"
                  className="flyer-image"
                />
              </div>
              <div className="flyer-card">
                <img
                  src="./images/LearnMoreNihocSports02.jpg"
                  alt="Flyer 2"
                  className="flyer-image"
                />
              </div>
              <div className="flyer-card">
                <img
                  src="./images/LearnMoreNihocSports03.jpg"
                  alt="Flyer 2"
                  className="flyer-image"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="gallery-container">
          <h2 className="LearnMorePage-section-title">Gallery</h2>
          <div className="gallery-grid">
            {images.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Gallery Image ${index + 1}`}
                className="gallery-image"
                onClick={() => setSelectedImage(src)}
              />
            ))}
          </div>
        </div>

        {selectedImage && (
          <div className="modal" onClick={() => setSelectedImage(null)}>
            <span className="close">&times;</span>
            <img src={selectedImage} alt="Selected" className="modal-content" />
          </div>
        )}

        <div className="LearnMorePage-section">
          <h2 className="LearnMorePage-section-title">Key Activities</h2>
          <div className="LearnMorePage-activities-grid">
            <div className="LearnMorePage-activity-card">
              <h2>Give your child the chance to learn and explore:</h2>
              <ul>
                <li>Horse Riding</li>
                <li>Artificial Intelligence</li>
                <li>Photography</li>
                <li>Dance</li>
                <li>DJ Mixing</li>
                <li>Arts & Craft</li>
                <li>Swimming</li>
                <li>Archery</li>
                <li>Chess</li>
                <li>Coding and Machine Learning</li>
                <li>And so much more!</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="LearnMorePage-section">
          <p className="LearnMorePage-section-content">
            Don’t wait! Limited seats are available. Your child could be
            Hyderabad’s next big innovator or entrepreneur!
          </p>
        </div>

        <Link to="/enrollment" className="styled-link">
          <button className="button-2">
            Register Today and Let the Journey Begin!
          </button>
        </Link>
      </div>

      <Footer />
    </>
  );
};

export default LearnMoreNihocSports;
