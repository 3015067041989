import React, { useState } from "react";
import Footer from "../components/Footer";
import ScrollAdd from "../components/ScrollAdd";
import Navbar from "../components/Navbar";

const Enrollment = () => {
  const [formData, setFormData] = useState({
    childName: "",
    dateOfBirth: "",
    gender: "",
    schoolName: "",
    gradeClass: "",
    parentName: "",
    relationshipToChild: "",
    contactNumber: "",
    emailAddress: "",
    emergencyContactPerson: "",
    emergencyContactNumber: "",
    hasAllergiesOrMedicalConditions: "",
    currentMedications: "",
    agreementConfirmed: false,
  });

  const [amount, setAmount] = useState(0);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value.trim(),
    }));
  
    // Update amount based on selected school
    if (name === "schoolName") {
      if (value.includes("₹4,500")) setAmount(4500);
      else if (value.includes("₹3,500")) setAmount(3500);
      else if (value.includes("₹10,000")) setAmount(10000);
      else if (value.includes("₹2,500")) setAmount(2500);
      else if (value.includes("₹4,000")) setAmount(4000);
      else setAmount(0);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formatDate = (date) => {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    };
    const formatGender = (gender) =>
      gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase();

    const postData = {
      child_full_name: formData.childName,
      date_of_birth: formatDate(formData.dateOfBirth),
      gender: formatGender(formData.gender),
      school_name: formData.schoolName,
      branch_name: formData.branchName,
      grade_class: formData.gradeClass,
      parent_full_name: formData.parentName,
      relationship_to_child: formData.relationshipToChild,
      contact_number: formData.contactNumber,
      email_address: formData.emailAddress,
      emergency_contact_person: formData.emergencyContactPerson,
      emergency_contact_number: formData.emergencyContactNumber,
      medical_conditions: formData.hasAllergiesOrMedicalConditions,
      current_medications: formData.currentMedications,
      amount: amount,
    };

    try {
      const response = await fetch(
        "https://learnoramabackend.learnorama.in/api/enroll",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error: ${errorData.error || "An unexpected error occurred."}`);
        return;
      }

      const data = await response.json();
      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      } else {
        alert(
          "Thank you! Your enrollment application has been submitted successfully."
        );
        window.location.href = "/";
      }
    } catch (error) {
      alert(
        "There was an error submitting your enrollment application. Please try again later."
      );
    }
  };

  return (
    <>
      <ScrollAdd />
      <Navbar />

      <div className="heading-main-box" style={{ marginTop: "30px" }}>
        <h5 style={{ color: "#000" }}>Learnorama</h5>
        <h1 id="font-color">TECH GENERATION SUMMER CAMP 2025</h1>
        <p style={{ textAlign: "center", fontWeight: "bold", color: "#000" }}>
          Organized by Learn Orama Technologies Pvt Ltd
        </p>
        <h1>Enrollment Application</h1>

        <h2 className="LearnMorePage-section-title">Logistics and Fees</h2>
        <p style={{ margin: "auto", textAlign: "center" }}>
          <strong>Fees:</strong>  Nihoc International
          School: ₹4,500 | Suprabhat Model High School: ₹3,500
          <br />
          <strong>Note:</strong> Transportation and hostel facilities are
          available.<strong>(Bookings open soon )</strong>
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <h2>Child's Information:</h2>

        <label>
          Full Name of Child:
          <input
            type="text"
            name="childName"
            value={formData.childName}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Date of Birth:
          <input
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Gender:
          <select
            style={{ color: "#000" }}
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required>
            <option style={{ color: "#000" }} value="">
              Select
            </option>
            <option style={{ color: "#000" }} value="male">
              Male
            </option>
            <option style={{ color: "#000" }} value="female">
              Female
            </option>
            <option style={{ color: "#000" }} value="other">
              Other
            </option>
          </select>
        </label>

        {/* <label>
          School Name:
          <input
            type="text"
            name="schoolName"
            value={formData.schoolName}
            onChange={handleChange}
            required
          />
        </label> */}

<label>
  Present School Name:
  <input
    type="text"
    name="branchName"
    value={formData.branchName}
    onChange={handleChange}
    required
  />
</label>

        <label>
          Select Your School for Summer Camp:
          <select
            name="schoolName"
            value={formData.schoolName}
            onChange={handleChange}
            required>
            <option value="">Select</option>
            
            <option value="Nihoc International School: ₹4,500">
              Nihoc International School: ₹4,500
            </option>
            <option value="Suprabhat Model High School: ₹3,500">
              Suprabhat Model High School: ₹3,500
            </option>
            <option value="TECH GENERATION Residential SUMMER CAMP 2025: ₹10,000">
            TECH GENERATION Residential SUMMER CAMP 2025: ₹10,000
            </option>
            <option value="Entry fee for each sport: ₹2,500">
            Learnorama 
Sports summer camp(Nihoc) Entry fee for each sport: ₹2,500
            </option>
            <option value="Entry fee for two sports: ₹4,000">
            Learnorama 
Sports summer camp(Nihoc) Entry fee for two sports: ₹4,000
            </option>
          </select>
        </label>
        {amount > 0 && (
          <p
            style={{
              textAlign: "center",
              margin: "10px 0",
              color: "#fff",
              border: "1px solid #fff",
            }}>
            Selected School Fee: ₹{amount}
          </p>
        )}

        <label>
          Grade/Class:
          <input
            type="text"
            name="gradeClass"
            value={formData.gradeClass}
            onChange={handleChange}
            required
          />
        </label>

        <h2>Parent/Guardian Information:</h2>

        <label>
          Full Name of Parent/Guardian:
          <input
            type="text"
            name="parentName"
            value={formData.parentName}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Relationship to Child:
          <input
            type="text"
            name="relationshipToChild"
            value={formData.relationshipToChild}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Contact Number:
          <input
            type="tel"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Email Address:
          <input
            type="email"
            name="emailAddress"
            value={formData.emailAddress}
            onChange={handleChange}
            required
          />
        </label>

        <h2>Emergency Contact Information:</h2>

        <label>
          Emergency Contact Person:
          <input
            type="text"
            name="emergencyContactPerson"
            value={formData.emergencyContactPerson}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Emergency Contact Number:
          <input
            type="tel"
            name="emergencyContactNumber"
            value={formData.emergencyContactNumber}
            onChange={handleChange}
            required
          />
        </label>

        <h2>Medical Information:</h2>

        <label>
          Does the child have any allergies or medical conditions? If yes,
          please provide details:
          <textarea
            name="hasAllergiesOrMedicalConditions"
            value={formData.hasAllergiesOrMedicalConditions}
            onChange={handleChange}
          />
        </label>

        <label>
          Current Medications (if any):
          <input
            type="text"
            name="currentMedications"
            value={formData.currentMedications}
            onChange={handleChange}
          />
        </label>

        <h2>Declaration:</h2>

        <label>
          <input
            type="checkbox"
            name="agreementConfirmed"
            checked={formData.agreementConfirmed}
            onChange={handleChange}
            required
          />
          I, the undersigned, hereby confirm that the information provided in
          this enrollment application is accurate and complete. I understand and
          agree to comply with the terms and conditions outlined by Learnorama
          Summer Camp.
        </label>

        <div
          style={{
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <button
            className="button-2"
            style={{ borderRadius: "25px", padding: "10px 20px" }}
            type="submit">
            Submit
          </button>
        </div>
      </form>

      <Footer />
    </>
  );
};

export default Enrollment;
